'use strict';
import { checkStatus } from './fetch';
import qs from 'qs';
import { format } from 'date-fns';
import compact from 'lodash/compact';
import { WheelEvent } from 'react';

// Copies the text of an input into the clipboard
const copyToClipboardFromInputId = async (inputId: string): Promise<boolean> => {
  const copyText = document.getElementById(inputId) as HTMLInputElement;

  if (!copyText) return Promise.resolve(false);

  try {
    await navigator.clipboard.writeText(copyText.value);

    return true;
  } catch {
    return false;
  }
};

// Copies text value to clipboard
const copyTextToClipboard = async (value: string): Promise<boolean> => {
  if (typeof value !== 'string') return Promise.resolve(false);

  try {
    await navigator.clipboard.writeText(value);

    return true;
  } catch {
    return false;
  }
};

// Returns string representation of a date, using the`date-fns.format` method, if the
// `dateInput` parameter is an invalid value for the Date constructor, it returns `null`.
const dateToString = (dateInput: string | Date, withTime?: boolean): null | string => {
  if (!dateInput) return null;

  const date = new Date(dateInput);
  if (isNaN(date.getTime())) return null;

  return withTime ? format(date, 'LLLL d, yyyy - H:mm') : format(date, 'LLLL d, yyyy');
};

// Returns date adjusted to the UTC time zone
const dateWithTimeZoneOffset = (dateInput: string | Date): null | Date => {
  if (!dateInput) return null;

  const date = new Date(dateInput);

  const timezoneOffsetInMilliseconds = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + timezoneOffsetInMilliseconds);
};

// Makes recursive requests for a given url merging data results on each iteration
const recursiveGet = async <T>(url: string, data: T[] = []): Promise<T[]> => {
  try {
    const parsed = await fetch(url)
      .then(checkStatus)
      .then((response) => response.json());
    const dataCombined = [...data, ...parsed.data];
    if (parsed.page && parsed.page.next_url) {
      return recursiveGet(`/api${parsed.page.next_url}`, dataCombined);
    }
    return Promise.resolve(dataCombined);
  } catch (error) {
    return Promise.reject(error);
  }
};

// Returns an array of objects with unique value for the property `id`.
const mergeObjectArrays = (
  baseArray: { id: string }[],
  incomingArray: { id: string }[]
): { id: string }[] => {
  const mergedArrays = baseArray.concat(incomingArray);
  const filteredArray = [];
  const map = new Map();

  for (const item of mergedArrays) {
    if (!map.has(item.id)) {
      map.set(item.id, true);
      filteredArray.push(item);
    }
  }

  return filteredArray;
};

// Sort array of objects alphabetically using a property value.
const sortObjectArrayAlphabetically = <T>(arr: T[], objKey: string): T[] => {
  return arr.sort((obj1, obj2) => {
    const comparedFieldObj1 = obj1[objKey].toLowerCase();
    const comparedFieldObj2 = obj2[objKey].toLowerCase();

    if (comparedFieldObj1 < comparedFieldObj2) {
      return -1;
    }
    if (comparedFieldObj1 > comparedFieldObj2) {
      return 1;
    }
    return 0;
  });
};

// Stringify object using URL Encoding
// e.g { filter: { keywords: ['water'] }, page: { number: 1 } } =>
// ?filter[keywords][]=water&page[number]=1
type ArrFormat = 'indices' | 'brackets' | 'repeat' | 'comma';
const createQueryString = (params = {}, arrayFormat: ArrFormat = 'brackets'): string => {
  if (Object.entries(params).length !== 0 && params.constructor === Object) {
    return '?' + qs.stringify(params, { arrayFormat: arrayFormat });
  }
  return '';
};

// Given an object, returns an array, where each key is repeated N times
// where N is the value of the key. e.g. { 'a': 2, 'b': 3 } => ['a', 'a', 'b', 'b', 'b']
const keyRepeater = (obj: object) =>
  Object.keys(obj).reduce((acc, cur) => [...acc, ...new Array(obj[cur] as number).fill(cur)], []);

// Smooth scroll to another section of the page.
// Shows the start block of the section.
const smoothScrollEventListener = (
  triggerElementQuery: string,
  scrollToElementId: string
): void => {
  const triggerElements = document.querySelectorAll(triggerElementQuery);
  const targetElement = document.getElementById(scrollToElementId);
  if (!triggerElements.length || !targetElement) return;

  const event = () => {
    targetElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  for (let i = 0; i < triggerElements.length; i++) {
    triggerElements[i].addEventListener('click', event);
  }
};

// Extracts slug and ID from a slugAndId variable
interface SplitSlugAndIdResult {
  id?: string;
  slug?: string;
}
const splitSlugAndId = (slugAndId: string): SplitSlugAndIdResult => {
  if (typeof slugAndId !== 'string') return {};
  if (!slugAndId.includes('-')) return {};

  const lastDashIndex = slugAndId.lastIndexOf('-');

  return {
    id: slugAndId.slice(lastDashIndex + 1),
    slug: slugAndId.substring(0, lastDashIndex),
  };
};

// Returns true if value of property `text` or `name` in object `option` is not present
// in any of the objects in the `elements` array
interface ItemOption {
  text?: string;
  name?: string;
}
interface ItemElements {
  title?: string;
  text?: string;
  name?: string;
}
const excludeSelectedItems = (option: ItemOption, elements: ItemElements[]): boolean => {
  return elements.every((element) => {
    return (element.title || element.text || element.name) !== (option.text || option.name);
  });
};

// Returns array of numbers from string of numbers separated by commas
const numbersFromCSV = (csv: string): number[] => {
  if (typeof csv !== 'string') return [];
  return csv
    .replace(/ /g, '')
    .split(',')
    .filter((stringValue) => stringValue && !isNaN(Number(stringValue)))
    .map((stringNumber) => Number(stringNumber));
};

// Delete element from the Rails cache
const deleteCachedElement = (id: string, objectType: string): Promise<{ ok: boolean }> => {
  const authTokenSelector = document.querySelector('meta[name="csrf-token"]');
  const authToken = authTokenSelector ? authTokenSelector.getAttribute('content') : null;

  if (!id || !objectType || !authToken) return Promise.reject('Invalid parameters');

  const options = {
    headers: {
      'X-CSRF-Token': authToken,
    },
    method: 'DELETE',
  };

  const endpoint = `/admin/cache_keys/${id}${createQueryString({ object_type: objectType })}`;

  return fetch(endpoint, options)
    .then(checkStatus)
    .catch((error) => Promise.reject(error));
};

const flushConsultantCache = (): Promise<{ ok: boolean }> => {
  const authTokenSelector = document.querySelector('meta[name="csrf-token"]');
  const authToken = authTokenSelector ? authTokenSelector.getAttribute('content') : null;

  if (!authToken) return Promise.reject('Invalid CSRF token');

  const options = {
    headers: {
      'X-CSRF-Token': authToken,
    },
    method: 'DELETE',
  };

  const CLEAR_USER_ACCOUNT_LEVEL_CACHE_PATH = '/admin/cache_keys/clear_user_account_level_cache';

  return fetch(CLEAR_USER_ACCOUNT_LEVEL_CACHE_PATH, options)
    .then(checkStatus)
    .catch((error) => Promise.reject(error));
};

// Given a number, format it so that it correctly uses commas
const formatNumberWithCommas = (number: string | number): string => {
  if (!number) return '';

  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

interface ParamFormatLocation {
  city?: { name?: string };
  location?: { name?: string };
}

// Format job location data for job item
const formatLocation = (location: ParamFormatLocation): string => {
  const cityName = location?.city?.name;
  const countryName = location?.location?.name;
  return compact([cityName, countryName]).join(', ');
};

// Prevents the input value change on mouse scrolling. Used mainly for number inputs.
const numberInputOnWheelPreventChange = (e: WheelEvent) => {
  const target = e.target as HTMLInputElement;
  // Prevent the input value change
  target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    target.focus();
  }, 0);
};

// Returns true if the param string is a valid email value, otherwise returns false.
const isEmailValid = (emailValue: string) => {
  if (!emailValue) return false;

  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
};

// Returns true if the param string is a valid website URL, otherwise returns false.
const isWebsiteURLValid = (websiteURL) => {
  if (!websiteURL || websiteURL.includes('@')) return false;

  try {
    let url = websiteURL;
    if (!/^https?:\/\//i.test(url)) {
      url = `http://${url}`;
    }

    const URLObject = new URL(url);

    const hostnameRegex = /^(?!-)([A-Za-z0-9-]{1,63}\.)+[A-Za-z]{2,6}$/;
    return hostnameRegex.test(URLObject.hostname);

  } catch {
    return false;
  }
};

export {
  copyTextToClipboard,
  copyToClipboardFromInputId,
  dateToString,
  dateWithTimeZoneOffset,
  recursiveGet,
  sortObjectArrayAlphabetically,
  mergeObjectArrays,
  createQueryString,
  keyRepeater,
  smoothScrollEventListener,
  splitSlugAndId,
  excludeSelectedItems,
  numbersFromCSV,
  deleteCachedElement,
  flushConsultantCache,
  formatNumberWithCommas,
  formatLocation,
  numberInputOnWheelPreventChange,
  isEmailValid,
  isWebsiteURLValid,
};
